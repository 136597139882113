import { ThunkAction } from "redux-thunk";
import { Action } from "redux";
import { AppState } from "../../reducers";
import {
  SignInState,
  SET_ACCOUNT_USER_PARAMS,
  SET_TOKEN,
  SignUpState,
  START_LOADING,
  STOP_LOADING,
  SET_SUCCESS,
  SET_FAILELD,
  ForgotPasswordState,
  ResetPasswordState,
  TokenState,
  SET_UPDATE_USER,
  SET_LIST_USERS,
  SET_TRAINER_PAYRATES,
  SET_LIST_USERS_REPORT,
  SET_USER_PROFILE,
  UPDATE_FRANCHISE,
  TrainerDetailsInfo,
  UPDATE_PACKAGE,
  UPDATE_PACKAGE_CURRENT,
  DELETE_USER,
  UserFilters,
  initialBankAccount,
  initialCreditCard,
  CLEAR_TOKEN,
  UPDATE_ACCOUNT_USER_PROFILE,
  SET_UPDATE_TRAINER_DETAILS,
  SET_FA_MAX_RATE,
  GET_FA_MAX_RATE,
  GET_USER_PAYMENT_LIST,
  GET_TRAINER_TRANSACTIONS_LIST,
  SET_LIST_USERS_WITH_PAGE,
  SET_TRAINER_PROFILE,
  SET_LIST_TRAINER_FILTER,
  SET_CHAT_TRAINER_PROFILE,
  SET_CATEGORIES_FILTERS,
  SET_SPECIALITY_FILTERS,
  SET_CERTIFICATE_FILTERS,
  SET_LANGUAGE_FILTERS,
  SET_USER_NEGATIVE_SESSION_REPORT,
  SET_STRIPE_REQUIREMENTS
  // FAILED_LIST_USERS
} from "../../../models";
import {
  postLogin,
  postSignUp,
  postForgotPass,
  postResetPass,
  getUserList,
  getUserById,
  changeUserAvatar,
  updateProfileAvatarApi,
  postUpdateProfile,
  addAdditionalInformation,
  changeUserStatus,
  updateTrainerUpdateDetailsInfo,
  setTrainerPayrateByCatApi,
  getTrainerPayratesByIdApi,
  deleteUser,
  checkUserProfileApi,
  changeOldPasswordApi,
  postOuathApi,
  updateFranchiseApi,
  getMaxRateAPI,
  setMaxRateAPI,
  getUserPaymentsApi,
  getTrainerTransactionsListAPI,
  getStripeUsl,
  resendVerificationMailApi,
  guestUserApi,
  deleteTempUserApi,
  postSignInWithAppleApi,
  getUserPaymentsListApi,
  getUserListWithPageApi,
  getUserDisableApi,
  getTrainerListFilter,
  createStandardFranchiseAdminAPi,
  getAccountLinksAPi,
  updateFranchiseKeysApi,
  isFranchsieKeysUpdatedApi,
  updateOsVersionApi,
  updateStandardFranchiseAdminAPi,
  shouldExpirySessionApi,
  getUserNegativeSessionReport,
  updateTrainerPayoutApi,
  checkUserNameAvailabilityApi,
  getTrainerAccountRequirementsAndStatusApi,
  postSignUpFromBroadcast
} from "../../../api";
import { startLoading, stopLoading } from "../index";
import { getCountries } from "../../../api/country";
import { sortUsersArraybyConcatingName } from "../../../helper";

// user action to signin through our api
export const userSigninAction = (
  params: SignInState
): ThunkAction<void, AppState, null, Action<string>> => {
  return async (dispatch: any, getState: any) => {
    try {
      startLoading(START_LOADING)(dispatch, getState, null);
      const response = await postLogin(params).xhr;
      // set account info
      if (response && response.user) {
        dispatch({ type: SET_ACCOUNT_USER_PARAMS, payload: response.user });
        dispatch({ type: SET_USER_PROFILE, payload: response.user });
        dispatch(getUserProfileAction(response.user.id));
      }
      
      // set token
      if (response && response.token) {
        dispatch({ type: SET_TOKEN, payload: response.token });
        return {
          firstLogin: response.firstLogin,
          userId: response.user.id,
          data: response,
        };
      }
    } catch (err) {
      // show error
      dispatch({ type: SET_FAILELD, payload: { message: err } });
      throw err;
    } finally {
      stopLoading(STOP_LOADING)(dispatch, getState, null);
    }
  };
};

// user action to signup through our api
export const userSignUpAction = (params: SignUpState): any => {
  return async (dispatch: any, getState: any) => {
    if (!params) {
      dispatch({
        type: SET_FAILELD,
        payload: { message: "Please fill all required fields." },
      });
      return false;
    }

    try {
      startLoading(START_LOADING)(dispatch, getState, null);
      const response = await postSignUp(params).xhr;

      if (response) {
        dispatch({
          type: SET_SUCCESS,
          payload: {
            success: true,
            message: "Verification code sent to your email.",
          },
        });
        return response;
      }
      return null;
    } catch (err) {
      // show error
      dispatch({ type: SET_FAILELD, payload: { message: err } });
      return false;
    } finally {
      stopLoading(STOP_LOADING)(dispatch, getState, null);
    }
  };
};

// user action to signup through our api
export const signUserFromBroadcast = (params: any): any => {
  return async (dispatch: any, getState: any) => {
    if (!params) {
      dispatch({
        type: SET_FAILELD,
        payload: { message: "Please fill all required fields." },
      });
      return false;
    }

    try {
      startLoading(START_LOADING)(dispatch, getState, null);
      const response = await postSignUpFromBroadcast(params).xhr;

      if (response) {
        dispatch({
          type: SET_SUCCESS,
          payload: {
            success: true,
            message: "Account created successfully.",
          },
        });
        return response;
      }
      return null;
    } catch (err) {
      // show error
      dispatch({ type: SET_FAILELD, payload: { message: err } });
      return false;
    } finally {
      stopLoading(STOP_LOADING)(dispatch, getState, null);
    }
  };
};

// user action to signup through our api
export const resendVerificationMail = (userEmail: string): any => {
  return async (dispatch: any, getState: any) => {
    if (!userEmail) {
      dispatch({
        type: SET_FAILELD,
        payload: { message: "Please fill all required fields." },
      });
      return false;
    }

    try {
      startLoading(START_LOADING)(dispatch, getState, null);
      const response = await resendVerificationMailApi(userEmail);
      
      if (response) {
        dispatch({
          type: SET_SUCCESS,
          payload: {
            success: true,
            message: "Verification code sent to your email.",
          },
        });
        return response;
      }
      return null;
    } catch (err) {
      // show error
      dispatch({ type: SET_FAILELD, payload: { message: err } });
      return false;
    } finally {
      stopLoading(STOP_LOADING)(dispatch, getState, null);
    }
  };
};
export const updateGoogleFacebookUser = (
  id: number,
  params: SignUpState
): ThunkAction<void, AppState, null, Action<string>> => {
  return async (dispatch: any, getState: any) => {
    if (!params) {
      dispatch({
        type: SET_FAILELD,
        payload: { message: "Please fill all required fields." },
      });
      return;
    }

    try {
      startLoading(START_LOADING)(dispatch, getState, null);
      const response = await postUpdateProfile(id, params).xhr;
      if (response) {
        // set account info
        
        if (response) {
          
          dispatch({ type: SET_ACCOUNT_USER_PARAMS, payload: response });
          dispatch({ type: SET_USER_PROFILE, payload: response });
          dispatch(getUserProfileAction(response.id));
          // set token
          
          // dispatch({ type: SET_TOKEN, payload: response.token });
          return { firstLogin: true, userId: response.id };
        }
      }
    } catch (err) {
      // show error
      dispatch({ type: SET_FAILELD, payload: { message: err } });
      return false;
    } finally {
      stopLoading(STOP_LOADING)(dispatch, getState, null);
    }
  };
};

export const addAdditionalInformationAction = (
  params: SignUpState
): ThunkAction<void, AppState, null, Action<string>> => {
  return async (dispatch: any, getState: any) => {
    
    if (!params) {
      dispatch({
        type: SET_FAILELD,
        payload: { message: "Please fill all required fields." },
      });
      return;
    }

    try {
      startLoading(START_LOADING)(dispatch, getState, null);
      const response = await addAdditionalInformation(params).xhr;
      if (response) {
        // set account info
        
        if (response) {
          dispatch({ type: UPDATE_ACCOUNT_USER_PROFILE, payload: params });
          // 200
          dispatch({
            type: SET_SUCCESS,
            payload: {
              success: true,
              message: "Information Added Successfully",
            },
          });
          return true;
        }
      }
    } catch (err) {
      // show error
      dispatch({ type: SET_FAILELD, payload: { message: err } });
      return false;
    } finally {
      stopLoading(STOP_LOADING)(dispatch, getState, null);
    }
  };
};

export const deleteTempUserAction = (params: {
  token: string;
}): ThunkAction<void, AppState, null, Action<string>> => {
  return async (dispatch: any, getState: any) => {
    try {
      const response = await deleteTempUserApi(params).xhr;
      return response;
    } catch (err) {
      // show error
      // dispatch({ type: SET_FAILELD, payload: { message: err } });
      return false;
    } finally {
      // stopLoading(STOP_LOADING)(dispatch, getState, null);
    }
  };
};

export const updateOsVersionAction = (data:{ os_type:string, os_version:string}, userId:string): ThunkAction<void, AppState, null, Action<string>> => {
  return async (dispatch: any, getState: any) => {
    try {
      const response = await updateOsVersionApi(data,userId).xhr;
      return response;
    } catch (err) {
      // show error
      // dispatch({ type: SET_FAILELD, payload: { message: err } });
      return false;
    } finally {
      // stopLoading(STOP_LOADING)(dispatch, getState, null);
    }
  };
};

export const guestUpAction = (params: {
  role: string;
}): ThunkAction<void, AppState, null, Action<string>> => {
  return async (dispatch: any, getState: any) => {
    try {
      startLoading(START_LOADING)(dispatch, getState, null);
      const response = await guestUserApi(params).xhr;
      if (response) {
        // set account info
        
        if (response) {
          if (response && response.token) {
            dispatch({ type: SET_TOKEN, payload: response.token });
            return response;
          }
          return {
            firstLogin: true,
            userId: response.user.id,
            user: response.user,
            token: response.token,
          };
        }
      }
    } catch (err) {
      // show error
      dispatch({ type: SET_FAILELD, payload: { message: err } });
      return false;
    } finally {
      stopLoading(STOP_LOADING)(dispatch, getState, null);
    }
  };
};

export const userUpdateProfile = (
  id: number,
  params: SignUpState
): ThunkAction<void, AppState, null, Action<string>> => {
  return async (dispatch: any, getState: any) => {
    if (!params) {
      dispatch({
        type: SET_FAILELD,
        payload: { message: "Please fill all required fields." },
      });
      return;
    }

    try {
      startLoading(START_LOADING)(dispatch, getState, null);
      const response = await postUpdateProfile(id, params).xhr;
      if (response) {
        dispatch({ type: UPDATE_ACCOUNT_USER_PROFILE, payload: params });
        // 200
        dispatch({
          type: SET_SUCCESS,
          payload: { success: true, message: "Update profile successful." },
        });
        return true;
      }

      //TODO
      // dispatch({type: UPDA, payload: response})
      // dispatch({type: UPDATE_PACKAGE_CURRENT, payload: response})
    } catch (err) {
      // show error
      dispatch({ type: SET_FAILELD, payload: { message: err } });
      return false;
    } finally {
      stopLoading(STOP_LOADING)(dispatch, getState, null);
    }
  };
};

export const trainerUpdateDetailsInfo = (
  id: number,
  params: any
): ThunkAction<void, AppState, null, Action<string>> => {
  return async (dispatch: any, getState: any) => {
    // if (!params) {
    //     dispatch({type: SET_FAILELD, payload: {message: "Please fill all required fields."}})
    //     return;
    // }

    try {
      startLoading(START_LOADING)(dispatch, getState, null);
      const response = await updateTrainerUpdateDetailsInfo(id, params).xhr;
      if (response) {
        dispatch({
          type: SET_UPDATE_TRAINER_DETAILS,
          payload: { id: id, searchable: params.searchable },
        });
      }
      dispatch({
        type: SET_SUCCESS,
        payload: {
          success: true,
          message: "Update Trainer Details Information successful.",
        },
      });
      return true;
    } catch (err) {
      // show error
      dispatch({ type: SET_FAILELD, payload: { message: err } });
      return false;
    } finally {
      stopLoading(STOP_LOADING)(dispatch, getState, null);
    }
  };
};

// user action to forgotpass through our api
export const userForgotPassAction = (
  params: ForgotPasswordState
): ThunkAction<void, AppState, null, Action<string>> => {
  return async (dispatch: any, getState: any) => {
    if (!params) {
      dispatch({
        type: SET_FAILELD,
        payload: { message: "Please fill all required fields." },
      });
      return;
    }

    try {
      startLoading(START_LOADING)(dispatch, getState, null);
      const response = await postForgotPass(params).xhr;

      // 200
      dispatch({
        type: SET_SUCCESS,
        payload: { success: true, message: "Password reset code sent to your email" },
      });
      return true;
    } catch (err) {
      // show error
      dispatch({ type: SET_FAILELD, payload: { message: err } });
      return false;
    } finally {
      stopLoading(STOP_LOADING)(dispatch, getState, null);
    }
  };
};

// user action to resetPassword through our api
export const userResetPassAction = (
  params: ResetPasswordState
): ThunkAction<void, AppState, null, Action<string>> => {
  return async (dispatch: any, getState: any) => {
    if (!params) {
      dispatch({
        type: SET_FAILELD,
        payload: { message: "Please fill all required fields." },
      });
      return;
    }

    try {
      startLoading(START_LOADING)(dispatch, getState, null);
      const response = await postResetPass(params).xhr;
      // 200
      dispatch({
        type: SET_SUCCESS,
        payload: { success: true, message: "Reset password" },
      });
      return true;
    } catch (err) {
      // show error
      dispatch({ type: SET_FAILELD, payload: { message: err } });
      return false;
    } finally {
      stopLoading(STOP_LOADING)(dispatch, getState, null);
    }
  };
};

export const getListUserAction = (filters: UserFilters | any): any => {
  return async (dispatch: any, getState: any) => {
    try {
      startLoading(START_LOADING)(dispatch, getState, null);
      const response = await getUserList(filters).xhr;
      if (response) {
        dispatch({ type: SET_LIST_USERS, payload: response });
        return response;
      }
    } catch (err) {
      // show error
      dispatch({ type: SET_FAILELD, payload: { message: err } });
    } finally {
      stopLoading(STOP_LOADING)(dispatch, getState, null);
    }
  };
};

export const getTrainerPayratesByIdAction = (trainer_id: number): any => {
  return async (dispatch: any, getState: any) => {
    try {
      startLoading(START_LOADING)(dispatch, getState, null);
      const response = await getTrainerPayratesByIdApi(trainer_id).xhr;
      if (response) {
        console.log("response action", response)
        dispatch({ type: SET_TRAINER_PAYRATES, payload: response });
        return response;
      }
    } catch (err) {
      // show error
      dispatch({ type: SET_FAILELD, payload: { message: err } });
    } finally {
      stopLoading(STOP_LOADING)(dispatch, getState, null);
    }
  };
};

export const setTrainerPayrateByCatAction = (userId: number, trainerDataId:number, categoryId: number, payrateArrToSubmit: any): ThunkAction<void, AppState, null, Action<string>> => {
  return async (dispatch: any, getState: any) => {
      try {
          startLoading(START_LOADING)(dispatch, getState, null)
          const response = await setTrainerPayrateByCatApi(userId, trainerDataId, categoryId, payrateArrToSubmit).xhr;

          // set account info
          if (response) {
              dispatch({
                  type: SET_SUCCESS,
                  payload: {success: true, message: 'Successful'}
              })
              dispatch({ type: SET_TRAINER_PAYRATES, payload: response });
              return response;
          }
          return true;
      } catch (err) {
          console.log(err);
          // show error
          dispatch({type: SET_FAILELD, payload: {message: err}})
          return false;
      } finally {
          stopLoading(STOP_LOADING)(dispatch, getState, null)
      }
  };
};

export const getListUserReportAction = (filters: UserFilters | any): any => {
  return async (dispatch: any, getState: any) => {
    try {
      startLoading(START_LOADING)(dispatch, getState, null);
      const response = await getUserList(filters).xhr;
      if (response) {
        dispatch({ type: SET_LIST_USERS_REPORT, payload: response });
        return response;
      }
    } catch (err) {
      // show error
      dispatch({ type: SET_FAILELD, payload: { message: err } });
    } finally {
      stopLoading(STOP_LOADING)(dispatch, getState, null);
    }
  };
};

export const getGenerateNegativeSessionAction = (): any => {
  return async (dispatch: any, getState: any) => {
    try {
      startLoading(START_LOADING)(dispatch, getState, null);
      const response = await getUserNegativeSessionReport().xhr;
      if (response) {
        dispatch({ type: SET_USER_NEGATIVE_SESSION_REPORT, payload: response });
        return response;
      }
    } catch (err) {
      // show error
      dispatch({ type: SET_FAILELD, payload: { message: err } });
    } finally {
      stopLoading(STOP_LOADING)(dispatch, getState, null);
    }
  };
};

export const getListTrainerAction = (filters: UserFilters | any): any => {
  return async (dispatch: any, getState: any) => {
    try {
      startLoading(START_LOADING)(dispatch, getState, null);
      const response = await getUserList(filters).xhr;
      if (response) {
        const r = response.filter((elem) => !getState().featuredTrainers.data.find((item: any) => {
          return elem.id === item.trainer_id}
        ));
        dispatch({ type: SET_LIST_USERS, payload: sortUsersArraybyConcatingName(r) });
        return response;
      }
    } catch (err) {
      // show error
      dispatch({ type: SET_FAILELD, payload: { message: err } });
    } finally {
      stopLoading(STOP_LOADING)(dispatch, getState, null);
    }
  };
};

export const getListUserWithPageAction = (filters: UserFilters | any): any => {
  return async (dispatch: any, getState: any) => {
    try {
      startLoading(START_LOADING)(dispatch, getState, null);
      const response = await getUserListWithPageApi(filters).xhr;
      if (response) {
        dispatch({ type: SET_LIST_USERS_WITH_PAGE, payload: response });
        return response;
      }
    } catch (err) {
      // show error
      dispatch({ type: SET_FAILELD, payload: { message: err } });
    } finally {
      stopLoading(STOP_LOADING)(dispatch, getState, null);
    }
  };
};
export const getTrainerListFilterAction = (filters: UserFilters | any): any => {
  return async (dispatch: any, getState: any) => {
    try {
      startLoading(START_LOADING)(dispatch, getState, null);
      const response = await getTrainerListFilter(filters).xhr;
      if (response) {
        dispatch({ type: SET_LIST_TRAINER_FILTER, payload: response });
        return response;
      }
    } catch (err) {
      // show error
      dispatch({ type: SET_FAILELD, payload: { message: err } });
    } finally {
      stopLoading(STOP_LOADING)(dispatch, getState, null);
    }
  };
};

export const getUserProfileAction = (
  id: number
): ThunkAction<void, AppState, null, Action<string>> => {
  return async (dispatch: any, getState: any) => {
    try {
      startLoading(START_LOADING)(dispatch, getState, null);
      const response = await getUserById(id).xhr;

      if (response) {
        response.Franchise.map((fr) => {
          return (fr.name = fr._Franchise.name);
        });
        if (response.TrainerDetails && response.TrainerDetails.Specialities) {
          response.TrainerDetails.Specialities.map((speciality) => {
            speciality.id = speciality.Speciality.id;
            speciality.name = speciality.Speciality.name;
            speciality.status = speciality.Speciality.status;
            delete speciality.Speciality;
            return speciality;
          });
        }
        if (response.TrainerDetails && response.TrainerDetails.Languages) {
          response.TrainerDetails.Languages.map((language) => {
            language.id = language.Language.id;
            language.name = language.Language.name;
            language.status = language.Language.status;
            delete language.Language;
            return language;
          });
        }
        if (response.TrainerDetails && response.TrainerDetails.Certifications) {
          response.TrainerDetails.Certifications.map((certificate) => {
            certificate.id = certificate.Certification.id;
            certificate.name = certificate.Certification.name;
            certificate.status = certificate.Certification.status;
            delete certificate.Certification;
            return certificate;
          });
        }
        if (response.TrainerDetails && response.TrainerDetails.Categories) {
          response.TrainerDetails.Categories.map((category: any) => {
            category.id = category.Category.id;
            category.name = category.Category.name;
            category.status = category.Category.status;
            delete category.Category;
            return category;
          });
        }
        response.bankData = { ...initialBankAccount };
        if (
          response.bankAccounts &&
          response.bankAccounts.data &&
          response.bankAccounts.data.length > 0
        ) {
          response.bankData = { ...response.bankAccounts.data[0] };
        }
        response.creditCardData = { ...initialCreditCard };
        if (
          response.bankAccounts &&
          response.bankAccounts.data &&
          response.bankAccounts.data.length > 0
        ) {
          response.creditCardData = { ...response.bankAccounts.data[0] };
        }
        dispatch({ type: SET_USER_PROFILE, payload: response });
      }
    } catch (err) {
      // show error
      dispatch({ type: SET_FAILELD, payload: { message: err } });
    } finally {
      stopLoading(STOP_LOADING)(dispatch, getState, null);
    }
  };
};

export const getTrainerProfileAction = (
  id: number
): ThunkAction<void, AppState, null, Action<string>> => {
  return async (dispatch: any, getState: any) => {
    try {
      startLoading(START_LOADING)(dispatch, getState, null);
      const response = await getUserById(id).xhr;

      if (response) {
        response.Franchise.map((fr) => {
          return (fr.name = fr._Franchise.name);
        });
        if (response.TrainerDetails && response.TrainerDetails.Specialities) {
          response.TrainerDetails.Specialities.map((speciality) => {
            speciality.id = speciality.Speciality.id;
            speciality.name = speciality.Speciality.name;
            speciality.status = speciality.Speciality.status;
            speciality.Speciality;
            return speciality;
          });
        }
        if (response.TrainerDetails && response.TrainerDetails.Certifications) {
          response.TrainerDetails.Certifications.map((certificate) => {
            certificate.id = certificate.Certification.id;
            certificate.name = certificate.Certification.name;
            certificate.status = certificate.Certification.status;
            certificate.Certification;
            return certificate;
          });
        }
        response.bankData = { ...initialBankAccount };
        if (
          response.bankAccounts &&
          response.bankAccounts.data &&
          response.bankAccounts.data.length > 0
        ) {
          response.bankData = { ...response.bankAccounts.data[0] };
        }
        response.creditCardData = { ...initialCreditCard };
        if (
          response.bankAccounts &&
          response.bankAccounts.data &&
          response.bankAccounts.data.length > 0
        ) {
          response.creditCardData = { ...response.bankAccounts.data[0] };
        }
        dispatch({ type: SET_TRAINER_PROFILE, payload: response });
      }
    } catch (err) {
      // show error
      dispatch({ type: SET_FAILELD, payload: { message: err } });
    } finally {
      stopLoading(STOP_LOADING)(dispatch, getState, null);
    }
  };
};

export const getChatTrainerProfileAction = (
  id: number
): ThunkAction<void, AppState, null, Action<string>> => {
  return async (dispatch: any, getState: any) => {
    try {
      startLoading(START_LOADING)(dispatch, getState, null);
      dispatch({ type: SET_CHAT_TRAINER_PROFILE, payload: {} });
      const response = await getUserById(id).xhr;

      if (response) {
        response.Franchise.map((fr) => {
          return (fr.name = fr._Franchise.name);
        });
        if (response.TrainerDetails && response.TrainerDetails.Specialities) {
          response.TrainerDetails.Specialities.map((speciality) => {
            speciality.id = speciality.Speciality.id;
            speciality.name = speciality.Speciality.name;
            speciality.status = speciality.Speciality.status;
            speciality.Speciality;
            return speciality;
          });
        }
        if (response.TrainerDetails && response.TrainerDetails.Certifications) {
          response.TrainerDetails.Certifications.map((certificate) => {
            certificate.id = certificate.Certification.id;
            certificate.name = certificate.Certification.name;
            certificate.status = certificate.Certification.status;
            certificate.Certification;
            return certificate;
          });
        }
        response.bankData = { ...initialBankAccount };
        if (
          response.bankAccounts &&
          response.bankAccounts.data &&
          response.bankAccounts.data.length > 0
        ) {
          response.bankData = { ...response.bankAccounts.data[0] };
        }
        response.creditCardData = { ...initialCreditCard };
        if (
          response.bankAccounts &&
          response.bankAccounts.data &&
          response.bankAccounts.data.length > 0
        ) {
          response.creditCardData = { ...response.bankAccounts.data[0] };
        }
        dispatch({ type: SET_CHAT_TRAINER_PROFILE, payload: response });
      }
    } catch (err) {
      // show error
      dispatch({ type: SET_FAILELD, payload: { message: err } });
    } finally {
      stopLoading(STOP_LOADING)(dispatch, getState, null);
    }
  };
};

export const setAvatarAction = (
  id: number,
  avatar: FormData
): ThunkAction<void, AppState, null, Action<string>> => {
  return async (dispatch: any, getState: any) => {
    try {
      startLoading(START_LOADING)(dispatch, getState, null);
      const response = await changeUserAvatar(id, avatar).xhr;
      if (response) {
        dispatch(getUserProfileAction(id));
        dispatch({
          type: SET_SUCCESS,
          payload: { success: true, message: "Avatar changed successfully" },
        });
        return true;
      }
    } catch (err) {
      // show error
      console.error(err);
      dispatch({
        type: SET_FAILELD,
        payload: { message: err.message },
      });
      return false;
    } finally {
      stopLoading(STOP_LOADING)(dispatch, getState, null);
    }
  };
};

export const updateProfileAvatarAction = (
  id: number,
  avatar: string
): ThunkAction<void, AppState, null, Action<string>> => {
  return async (dispatch: any, getState: any) => {
    try {
      startLoading(START_LOADING)(dispatch, getState, null);
      const response = await updateProfileAvatarApi(avatar).xhr;
      if (response) {
        dispatch(getUserProfileAction(id));
        dispatch({
          type: SET_SUCCESS,
          payload: { success: true, message: response.message },
        });
        return response;
      }
    } catch (err) {
      // show error
      console.error(err);
      dispatch({
        type: SET_FAILELD,
        payload: { message: err.message },
      });
      return false;
    } finally {
      stopLoading(STOP_LOADING)(dispatch, getState, null);
    }
  };
};

export const setUserStatusAction = (
  id: number
): ThunkAction<void, AppState, null, Action<string>> => {
  return async (dispatch: any, getState: any) => {
    try {
      startLoading(START_LOADING)(dispatch, getState, null);
      const response = await changeUserStatus(id).xhr;
      if (response) {
        dispatch({
          type: SET_SUCCESS,
          payload: {
            success: true,
            message: "User Status changed successfully",
          },
        });
        dispatch({ type: SET_UPDATE_USER, payload: response });
      }
    } catch (err) {
      // show error
      dispatch({ type: SET_FAILELD, payload: { message: err } });
    } finally {
      stopLoading(STOP_LOADING)(dispatch, getState, null);
    }
  };
};

export const deleteUserAction = (
  id: number
): ThunkAction<void, AppState, null, Action<string>> => {
  return async (dispatch: any, getState: any) => {
    try {
      startLoading(START_LOADING)(dispatch, getState, null);
      const response = await deleteUser(id).xhr;
      if (response) {
        dispatch({
          type: SET_SUCCESS,
          payload: { success: true, message: "User Deleted successfully" },
        });
        dispatch({ type: DELETE_USER, payload: id });
        return true;
      }
      return false;
    } catch (err) {
      // show error
      dispatch({ type: SET_FAILELD, payload: { message: err } });
      return false;
    } finally {
      stopLoading(STOP_LOADING)(dispatch, getState, null);
    }
  };
};

export const setTokenAction = (
  params: TokenState
): ThunkAction<void, AppState, null, Action<string>> => {
  return async (dispatch: any) => {
    try {
      await dispatch({ type: SET_TOKEN, payload: params });
      return true;
    } catch (err) {
      return false;
      // TODO: dispatch 401
    }
  };
};

export const removeTokenAction = (): ThunkAction<
  void,
  AppState,
  null,
  Action<string>
> => {
  return async (dispatch: any) => {
    try {
      await dispatch({ type: CLEAR_TOKEN });
      return true;
    } catch (err) {
      console.error(err);
      return false;
      // TODO: dispatch 401
    }
  };
};

export const checkUserProfileAction = (): ThunkAction<
  void,
  AppState,
  null,
  Action<string>
> => {
  return async (dispatch: any, getState: any) => {
    try {
      startLoading(START_LOADING)(dispatch, getState, null);
      const response = await checkUserProfileApi();
      
      if (response) {
        return response;
      }
    } catch (err) {
      // dispatch({type: SET_FAILELD, payload: {message: err}})
      return false;
    } finally {
      stopLoading(STOP_LOADING)(dispatch, getState, null);
    }
  };
};

export const changeOldPasswordAction = (
  oldPassword: string,
  newPassword: string
): ThunkAction<void, AppState, null, Action<string>> => {
  return async (dispatch: any, getState: any) => {
    try {
      startLoading(START_LOADING)(dispatch, getState, null);
      const response = await changeOldPasswordApi(oldPassword, newPassword);
      if (response) {
        dispatch({
          type: SET_SUCCESS,
          payload: { success: true, message: "Password Updated successfully" },
        });
        return true;
      }
    } catch (err) {
      dispatch({ type: SET_FAILELD, payload: { message: err } });
      return false;
    } finally {
      stopLoading(STOP_LOADING)(dispatch, getState, null);
    }
  };
};

export const ouathAction = (
  provider: string,
  data: any
): ThunkAction<void, AppState, null, Action<string>> => {
  return async (dispatch: any, getState: any) => {
    try {
      startLoading(START_LOADING)(dispatch, getState, null);
      const response = await postOuathApi(provider, data);
      // set account info
      if (response && response.user) {
        dispatch({ type: SET_ACCOUNT_USER_PARAMS, payload: response.user });
        dispatch({ type: SET_USER_PROFILE, payload: response.user });
        dispatch(getUserProfileAction(response.user.id));
      }

      // set token
      if (response && response.token) {
        dispatch({ type: SET_TOKEN, payload: response.token });
        return response;
      }
    } catch (err) {
      dispatch({ type: SET_FAILELD, payload: { message: err } });
      return false;
    } finally {
      stopLoading(STOP_LOADING)(dispatch, getState, null);
    }
  };
};

export const updateFranchiseUserAction = (
  franchise: { franchise_id: string; active: boolean }[],
  userId: string,
  isAdd: boolean
): ThunkAction<void, AppState, null, Action<string>> => {
  return async (dispatch: any, getState: any) => {
    try {
      startLoading(START_LOADING)(dispatch, getState, null);
      const {
        account: {
          userProfile: { Franchise },
        },
      } = getState();
      let ListFranchise = [];
      if (isAdd) {
        ListFranchise = Franchise.map((_franchise: any) => {
          return {
            franchise_id: _franchise.franchise_id,
            active: _franchise.active,
          };
        });
      } else {
        ListFranchise = Franchise.map((_franchise: any) => {
          return {
            franchise_id: _franchise.franchise_id,
            active: false,
          };
        });

        ListFranchise = ListFranchise.filter(
          (_franchise: any) =>
            _franchise.franchise_id !== franchise[0].franchise_id
        );
      }
      // ListFranchise.push(franchise)
      let response
      if(isAdd) {
        response = await updateFranchiseApi(
          { franchise: [...ListFranchise, ...franchise] },
          userId
        );
      } else {
        response = await updateFranchiseApi(
          { franchise: [...franchise] },
          userId
        );

      }
      if (response) {
        await dispatch(getUserProfileAction(+userId));
        return true;
      }
    } catch (err) {
      dispatch({ type: SET_FAILELD, payload: { message: err } });
      return false;
    } finally {
      stopLoading(STOP_LOADING)(dispatch, getState, null);
    }
  };
};

export const setMaxRateAction = (
  provider: string,
  data: any
): ThunkAction<void, AppState, null, Action<string>> => {
  return async (dispatch: any, getState: any) => {
    try {
      startLoading(START_LOADING)(dispatch, getState, null);
      const response = await setMaxRateAPI(data);

      dispatch({ type: SET_FA_MAX_RATE, payload: response });
      return response;
    } catch (err) {
      dispatch({ type: SET_FAILELD, payload: { message: err } });
      return false;
    } finally {
      stopLoading(STOP_LOADING)(dispatch, getState, null);
    }
  };
};

export const getMaxRateAction = (
  provider: string,
  data: any
): ThunkAction<void, AppState, null, Action<string>> => {
  return async (dispatch: any, getState: any) => {
    try {
      startLoading(START_LOADING)(dispatch, getState, null);
      const response = await getMaxRateAPI();
      dispatch({ type: GET_FA_MAX_RATE, payload: response });
    } catch (err) {
      dispatch({ type: SET_FAILELD, payload: { message: err } });
      return false;
    } finally {
      stopLoading(STOP_LOADING)(dispatch, getState, null);
    }
  };
};

export const getUserPaymentsAction = (
  id: number
): ThunkAction<void, AppState, null, Action<string>> => {
  return async (dispatch: any, getState: any) => {
    try {
      startLoading(START_LOADING)(dispatch, getState, null);
      const response = await getUserPaymentsApi(id);
      if (response) {
        dispatch({ type: GET_USER_PAYMENT_LIST, payload: response });
      }
    } catch (err) {
      dispatch({ type: SET_FAILELD, payload: { message: err } });
      return false;
    } finally {
      stopLoading(STOP_LOADING)(dispatch, getState, null);
    }
  };
};


export const getUserPaymentsListAction = (
  id: number
): ThunkAction<void, AppState, null, Action<string>> => {
  return async (dispatch: any, getState: any) => {
    try {
      startLoading(START_LOADING)(dispatch, getState, null);
      const response = await getUserPaymentsListApi(id);
      if (response) {
        dispatch({ type: GET_USER_PAYMENT_LIST, payload: response });
      }
    } catch (err) {
      dispatch({ type: SET_FAILELD, payload: { message: err } });
      return false;
    } finally {
      stopLoading(STOP_LOADING)(dispatch, getState, null);
    }
  };
};


export const getTrainerTransactionsListAction = (
  trainerId: number
): ThunkAction<void, AppState, null, Action<string>> => {
  return async (dispatch: any, getState: any) => {
    try {
      startLoading(START_LOADING)(dispatch, getState, null);
      const response = await getTrainerTransactionsListAPI(trainerId);
      
      if (response) {
        dispatch({ type: GET_TRAINER_TRANSACTIONS_LIST, payload: response });
      }
    } catch (err) {
      dispatch({ type: SET_FAILELD, payload: { message: err } });
      return false;
    } finally {
      stopLoading(STOP_LOADING)(dispatch, getState, null);
    }
  };
};

export const getStripeUrlAction = (): ThunkAction<
  void,
  AppState,
  null,
  Action<string>
> => {
  return async (dispatch: any, getState: any) => {
    try {
      startLoading(START_LOADING)(dispatch, getState, null);
      const response = await getStripeUsl();
      if (response) {
        return response;
      }
    } catch (err) {
      dispatch({ type: SET_FAILELD, payload: { message: err } });
      return false;
    } finally {
      stopLoading(STOP_LOADING)(dispatch, getState, null);
    }
  };
};

export const getCountriesAction = (): ThunkAction<
  void,
  AppState,
  null,
  Action<string>
> => {
  return async (dispatch: any, getState: any) => {
    try {
      startLoading(START_LOADING)(dispatch, getState, null);
      const response = await getCountries();
      if (response) {
        return response;
      }
    } catch (err) {
      dispatch({ type: SET_FAILELD, payload: { message: err } });
      return false;
    } finally {
      stopLoading(STOP_LOADING)(dispatch, getState, null);
    }
  };
};

export const signInWithAppleAction = (
  data: any
): ThunkAction<void, AppState, null, Action<string>> => {
  return async (dispatch: any, getState: any) => {
    try {
      startLoading(START_LOADING)(dispatch, getState, null);
      const response = await postSignInWithAppleApi(data);
      // set account info
      if (response && response.user) {
        dispatch({ type: SET_ACCOUNT_USER_PARAMS, payload: response.user });
        dispatch({ type: SET_USER_PROFILE, payload: response.user });
        dispatch(getUserProfileAction(response.user.id));
      }

      // set token
      if (response && response.token) {
        dispatch({ type: SET_TOKEN, payload: response.token });
        return response;
      }
    } catch (err) {
      dispatch({ type: SET_FAILELD, payload: { message: err } });
      return false;
    } finally {
      stopLoading(STOP_LOADING)(dispatch, getState, null);
    }
  };
};

export const getUserDisableAction = (user_id:number): ThunkAction<
  void,
  AppState,
  null,
  Action<string>
> => {
  return async () => {
    try {
      const response = await getUserDisableApi(user_id);
      if (response) {
        return response;
      }else{
       
      }
    } catch (err) {
      
    } 
  };
};


// user action to signup through our api
export const createStandardFranchiseAdminAction = (data: any): any => {
  return async (dispatch: any, getState: any) => {
    try {
      startLoading(START_LOADING)(dispatch, getState, null);
      const response = await createStandardFranchiseAdminAPi(data).xhr;
      if (response) {
        dispatch({
          type: SET_SUCCESS,
          payload: {
            success: true,
            message: "Franchise Admin created.",
          },
        });
        return response;
      }
      return null;
    } catch (err) {
      // show error
      dispatch({ type: SET_FAILELD, payload: { message: err } });
      return false;
    } finally {
      stopLoading(STOP_LOADING)(dispatch, getState, null);
    }
  };
};

export const updateStandardFranchiseAdminAction = (data: any): any => {
  return async (dispatch: any, getState: any) => {
    try {
      startLoading(START_LOADING)(dispatch, getState, null);
      const response = await updateStandardFranchiseAdminAPi(data).xhr;
      if (response) {
        dispatch({
          type: SET_SUCCESS,
          payload: {
            success: true,
            message: "Franchise Admin updated.",
          },
        });
        return response;
      }
      return null;
    } catch (err) {
      // show error
      dispatch({ type: SET_FAILELD, payload: { message: err } });
      return false;
    } finally {
      stopLoading(STOP_LOADING)(dispatch, getState, null);
    }
  };
};

export const getAccountLinksAction = () => {
  return async (dispatch: any, getState: any) => {
    try {
      startLoading(START_LOADING)(dispatch, getState, null);
      const response:any = await getAccountLinksAPi();
      return response
    } catch (err) {
      return false;
    } finally {
      stopLoading(STOP_LOADING)(dispatch, getState, null);
    }
  };
};


// user action to signup through our api
export const updateFranchiseKeysAction = (data: any): any => {
  return async (dispatch: any, getState: any) => {
    try {
      startLoading(START_LOADING)(dispatch, getState, null);
      const response = await updateFranchiseKeysApi(data).xhr;
      if (response) {
        dispatch({
          type: SET_SUCCESS,
          payload: {
            success: true,
            message: "Keys updated.",
          },
        });
        return response;
      }
      return null;
    } catch (err) {
      // show error
      dispatch({ type: SET_FAILELD, payload: { message: err } });
      return false;
    } finally {
      stopLoading(STOP_LOADING)(dispatch, getState, null);
    }
  };
};


export const isFranchsieKeysUpdatedAction = () => {
  return async (dispatch: any, getState: any) => {
    try {
      startLoading(START_LOADING)(dispatch, getState, null);
      const response:any = await isFranchsieKeysUpdatedApi();
      return response
    } catch (err) {
      return false;
    } finally {
      stopLoading(STOP_LOADING)(dispatch, getState, null);
    }
  };
};

export const shouldExpirySessionAction = (data:any) => {
  return async (dispatch: any, getState: any) => {
    try {
      const response:any = await shouldExpirySessionApi(data);
      return response
    } catch (err) {
      return false;
    }
  };
};

export const setCategoryFilter = (data:any) => {  
  return async (dispatch: any, getState: any) => {
    try {
      dispatch({
        type: SET_CATEGORIES_FILTERS,
        payload: data,
      });
    } catch (err) {
      return false;
    }
  };
};

export const setSpecialityFilter = (data:any) => {
  return async (dispatch: any, getState: any) => {
    try {
      dispatch({
        type: SET_SPECIALITY_FILTERS,
        payload: data,
      });
    } catch (err) {
      return false;
    }
  };
};

export const setCertificateFilter = (data:any) => {
  return async (dispatch: any, getState: any) => {
    try {
      dispatch({
        type: SET_CERTIFICATE_FILTERS,
        payload: data,
      });
    } catch (err) {
      return false;
    }
  };
};

export const setLanguageFilter = (data:any) => {
  return async (dispatch: any, getState: any) => {
    try {
      dispatch({
        type: SET_LANGUAGE_FILTERS,
        payload: data,
      });
    } catch (err) {
      return false;
    }
  };
};

export const updateTrainerPayoutAction = (payoutTrainer:boolean, trainer_id:number): ThunkAction<any, AppState, null, Action<string>> => {
  return async (dispatch, getState)=>{
      try{
          startLoading(START_LOADING)(dispatch, getState, null)
          let response = await updateTrainerPayoutApi(payoutTrainer, trainer_id).xhr;
          if(response){
            dispatch({ type: SET_UPDATE_USER, payload: response });
            dispatch({type: SET_SUCCESS, payload: {success: true, message: 'Trainer Payout Updated!'}})
            return response;
          }
      }catch(err){
          dispatch({type: SET_FAILELD, payload: { message: err }})
      }finally{
          stopLoading(STOP_LOADING)(dispatch, getState, null)
      }
  }
};

// user action to check if email exists through our api
export const checkUserNameAvailabilityAction = (email: string): ThunkAction<any, AppState, null, Action<string>> => {
    return async (dispatch: any, getState: any) => {
        try {
            startLoading(START_LOADING)(dispatch, getState, null)
            const response = await checkUserNameAvailabilityApi(email).xhr;
            // set account info
            if (response) {
                if(response) {
                    dispatch({type: SET_FAILELD, payload: {message: response?.error}})
                    return response
                }
            }

        } catch (err) {
            // show error
            dispatch({type: SET_FAILELD, payload: {message: err}})
        } finally {
            stopLoading(STOP_LOADING)(dispatch, getState, null)
        }
    };
};


export const updateUserProfileStoreAction = (payload:any) => {
  return (dispatch:any)=>{
    dispatch({ type: SET_USER_PROFILE, payload });
  }
}


export const getTrainerAccountRequirementsAndStatusAction = (): ThunkAction<any, AppState, null, Action<string>> => {
  return async (dispatch, getState)=>{
      try{
          startLoading(START_LOADING)(dispatch, getState, null)
          let response = await getTrainerAccountRequirementsAndStatusApi().xhr;
          if(response){
            dispatch({type: SET_STRIPE_REQUIREMENTS, payload: response});
            return response;
          }
      }catch(err){
          dispatch({type: SET_FAILELD, payload: { message: err }})
      }finally{
          stopLoading(STOP_LOADING)(dispatch, getState, null)
      }
  }
};